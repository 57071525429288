<template>
  <v-col
    v-if="subStep"
    cols="12"
    md="4"
    align-self="center"
    class="justify-center"
  >
    <v-card class="flex-card avatar-panel-container" height="100%">
      <v-card-text v-show="showAvatar">
        <v-container fluid class="pa-0">
          <v-row>
            <v-col
              cols="10"
              offset="1"
              class="d-flex justify-center img-container"
            >
              <img
                :class="`img-frame img-transition ${cGlowAvatarFrame}`"
                :src="avatar.realUrl"
              />
              <img
                :class="
                  `img-frame img-transition ${cHideAvatarImage} ${cGlowAvatarFrame}`
                "
                :src="avatar.url"
              />
              <img
                class="img-spacer"
                :src="avatar.url"
              /> 
            </v-col>
          </v-row>
          <v-row>  
            <v-col class="text-center">
              <h4 v-html=avatar.name class="avatar-name text-center mb-0">
              </h4>
              <h6 v-html=avatar.location class="avatar-description text-center ma-0">
              </h6>
              <h6 v-html=avatar.avatarDescription class="avatar-description text-center ma-0">
              </h6>
              <v-btn v-if="avatar.random"
                class="mt-2 white--text caption font-weight-bold randomizer-chip"
                small
                elevation="0"
                :ripple="false"
                color="#ff3f3f"
              >
                <v-icon left>
                  mdi-atom-variant
                </v-icon>Randomizer
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p v-html="avatar.bio" class="avatar-bio text-center px-6 mb-0"></p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="flex text-center">
              <avatar-resources v-for="(avatarResource, index) in avatarResources" :key="index"
                :buttonName=avatarResource.name
                :buttonUrl=avatarResource.url
              >
              </avatar-resources>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <div v-show="!isMobile">
        <v-divider></v-divider>
        <v-card-actions v-show="showAvatar" class="justify-center">
          <v-container>
            <v-row no-gutters dense>
              <v-col class="d-flex justify-center">
                <notes
                  v-if="user"
                  :currentStep="currentStep"
                  :currentSubStep="currentSubStep"
                  :user="user"
                ></notes>
                <v-btn
                  v-show="backButton"
                  style="z-index: 9; min-width: 50px; width: 50px;"
                  class="white--text caption font-weight-bold mr-2"
                  large
                  elevation="2"
                  rounded
                  color="orange"
                  @click="previousSubStep()"
                >
                  <v-icon>mdi-arrow-left-circle-outline</v-icon>
                </v-btn>
                <v-btn
                  class="white--text caption font-weight-bold"
                  large
                  elevation="2"
                  rounded
                  :disabled="!continueButtonEnabled"
                  color="orange"
                  @click="nextSubStep()"
                >
                  <span style="font-size: 1.4em;">Continue</span>
                  <v-icon right>
                    mdi-arrow-right-circle-outline
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </div>
    </v-card>
    <v-btn
      v-if="isMobile"
      v-show="backButton"
      style="z-index: 9;"
      color="orange"
      class="rounded-pill"
      dark
      fixed
      bottom
      left
      @click="previousSubStep()"
    >
      <v-icon>mdi-arrow-left-circle-outline</v-icon>
    </v-btn>
    <notes
      v-if="user && isMobile"
      :currentStep="currentStep"
      :currentSubStep="currentSubStep"
      :user="user"
      :isFloating="true"
    ></notes>
    <v-btn
      v-if="isMobile"
      class="white--text caption font-weight-bold"
      elevation="2"
      rounded
      :disabled="!continueButtonEnabled"
      color="orange"
      @click="nextSubStep()"
      fixed
      bottom
      right
      style="z-index: 9;"
    >
      <span style="font-size: 1.3em;">Continue</span>
      <v-icon right>
        mdi-arrow-right-circle-outline
      </v-icon>
    </v-btn>
    <confirm
      ref="avatarConfirmModal"
      :showButton="false"
      :title="modal.title"
      :description="modal.description"
      buttonConfirmLabel="Yes"
      buttonConfirmColor="green"
      buttonCancelLabel="No"
      buttonCancelColor="black"
      :buttonConfirmCallback="
        () => {
          this.modal.onConfirm();
        }
      "
    ></confirm>
    <div v-if="isMobile" class="mobileBottom"></div>
  </v-col>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import Confirm from "/src/components/modals/Confirm.vue";
import Notes from "/src/components/modals/Notes.vue";
import AvatarResources from "/src/components/buttons/AvatarResources.vue";
export default {
  name: "AvatarPanel",
  components: {
    Confirm,
    Notes,
    AvatarResources
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    step: {
      type: Object,
      required: true,
    },
    subStep: {
      type: Object,
      required: true,
    },
    backButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    subStepOrderMap: {
      type: Object,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showAvatar: true,
      glowAvatarFrame: false,
      modal: {
        title: "",
        description: "",
        onConfirm: () => {
          console.log("not set");
        },
      },
    };
  },
  computed: {
    ...mapState({
      avatar: (state) => state.avatar,
      introduction: (state) => state.introduction,
      caseData: (state) => state.caseData,
      continueButtonEnabled: (state) => state.continueButtonEnabled,
      currentStep: (state) => state.currentStep,
      currentSubStep: (state) => state.currentSubStep,
      forceNextSubStepTo: (state) => state.forceNextSubStepTo,
      user: (state) => state.user,
      avatarResources() {
        if (this.avatar.variables) {
          let avatarVariables = JSON.parse(this.avatar.variables);
          if (avatarVariables.resources) {
            return avatarVariables.resources;
          }
        }
        return null;
      },
    }),
    subsStepsAmount() {
      if (this.step && this.step.sub_steps) {
        return this.step.sub_steps.length;
      }
      return 0;
    },
    stepsAmount() {
      if (this.steps) {
        return this.steps.length;
      }
      return 0;
    },
    cHideAvatarImage() {
      if (this.subStep.component !== "step-avatar" && this.subStep.component !== "step-avatar-map") {
        return "img-transition-in";
      }
      return "";
    },
    cGlowAvatarFrame() {
      if (this.glowAvatarFrame) {
        return "shadow-movement";
      }
      return "";
    },
  },
  watch: {
    currentSubStep() {
      this.setContinueButtonForLastStep();
    },
    cHideAvatarImage(newVal) {
      if (newVal === "img-transition-in") {
        this.glowAvatarFrame = true;
        setTimeout(() => {
          this.glowAvatarFrame = false;
        }, 3000);
      }
    },
  },
  created() {
    this.$root.$refs.AvatarPanel = this;
  },
  beforeMount() {
    this.setContinueButtonForLastStep();
  },
  methods: {
    ...mapMutations([
      "setContinueButtonEnabled",
      "setCurrentStep",
      "setCurrentSubStep",
      "setIntroduction",
      "setForceNextSubStepTo",
      "setShowCredit",
      "setIsLastSubStepFromLastStep",
      "setLastNavigationWas",
      "setLastSubStepIdWas",
    ]),
    ...mapActions(["setCaseData", "trackingAction"]),
    setContinueButtonForLastStep() {
      if (this.isLastSubStepFromLastStep()) {
        this.setContinueButtonEnabled(false);
      }
    },
    findPreviousSubStepIdByOrderField() {
      let previusSubStepId = null;
      const navigationData = this.getSubStepNavigationData();
      const orderKeys = navigationData.orderKeys;
      const orderKeysPos = navigationData.orderKeysPos;

      if (orderKeysPos > 0) {
        const previusStepOrderValue = orderKeys[orderKeysPos - 1];
        previusSubStepId = this.subStepOrderMap.orderToId[this.currentStep][
          previusStepOrderValue
        ];
      }

      return previusSubStepId;
    },
    findNextSubStepIdByOrderField() {
      let nextSubStepId = null;

      const navigationData = this.getSubStepNavigationData();
      const orderKeys = navigationData.orderKeys;
      const orderKeysPos = navigationData.orderKeysPos;

      const nextStepOrderValue = orderKeys[orderKeysPos + 1];
      if (nextStepOrderValue) {
        nextSubStepId = this.subStepOrderMap.orderToId[this.currentStep][
          nextStepOrderValue
        ];
      }
      return nextSubStepId;
    },
    getSubStepNavigationData() {
      const currentOrderPos = this.subStepOrderMap.idToOrder[this.currentStep][
        this.currentSubStep
      ].toString();

      const orderKeys = Object.keys(
        this.subStepOrderMap.orderToId[this.currentStep]
      );
      const orderKeysPos = orderKeys.indexOf(currentOrderPos);

      return { orderKeys, orderKeysPos };
    },
    previousSubStep() {
      this.setLastSubStepIdWas(this.currentSubStep);
      this.setForceNextSubStepTo(null);
      const previusSubStepId = this.findPreviousSubStepIdByOrderField();
      if (previusSubStepId) {
        this.setCurrentSubStep(previusSubStepId);
      } else {
        this.setIntroduction(true);
      }
      this.trackingAction(`Back Button to SubStep: ${previusSubStepId}`);
      this.setContinueButtonEnabled(true);
      this.scrollToTop();
      this.setLastNavigationWas("back");
    },
    nextSubStep() {
      this.setLastSubStepIdWas(this.currentSubStep);
      if (this.forceNextSubStepTo) {
        const goToSubstep = parseInt(this.forceNextSubStepTo);
        this.setForceNextSubStepTo(null);
        this.setCurrentSubStep(goToSubstep);
        this.setLastNavigationWas("continue");
        this.trackingAction(`Continue Button to SubStep: ${goToSubstep}`);
      } else {
        const nextSubStepId = this.findNextSubStepIdByOrderField();
        if (nextSubStepId) {
          this.setCurrentSubStep(nextSubStepId);
          this.setLastNavigationWas("continue");
          this.trackingAction(`Continue Button to SubStep: ${nextSubStepId}`);
        } else if (this.currentStep < this.stepsAmount) {
          this.moveToNextStep();
          this.trackingAction(`Continue Button to Case: ${nextStep}`);
        }
      }
      this.setContinueButtonEnabled(true);
      this.scrollToTop();
    },
    scrollToTop() {
      if (this.isMobile) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    moveToNextStep() {
      this.clearCurrentCaseData();
      this.setCurrentStep(nextStep);
      const firstSubStep = this.step.sub_steps[0].id;
      this.setCurrentSubStep(firstSubStep);
    },
    clearCurrentCaseData() {
      this.setCaseData({});
    },
    modalShowCredits() {
      this.setShowCredit(true);
    },
    isLastSubStepFromLastStep() {
      const nextSubStepId = this.findNextSubStepIdByOrderField();
      if (!nextSubStepId && !(this.currentStep < this.stepsAmount)) {
        this.setIsLastSubStepFromLastStep(true);
        return true;
      }
      this.setIsLastSubStepFromLastStep(false);
      return false;
    },
  },
};
</script>

<style>
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: rgba(46, 38, 38, 0.325) !important;
}



.avatar-name span,
.avatar-description span,
.avatar-bio span {
  display: inline-block;
}

.avatar-name {
  padding-top: 5px;
}

.avatar-bio {
  font-size: 15px;
}

.img-container {
  /* min-height: 265px; */
  position: relative;
  margin: 0;
}

.img-spacer {
  opacity: 0 !important;
  z-index: -20 !important;
  margin: 0 !important;
  padding: 0 !important;
}

.img-transition {
  transition: opacity 2s linear;
  position: absolute;
  top: 0;
}

.img-transition-in:hover {
  opacity:1;
  transition: opacity .5s linear;
}

.img-transition-in {
  opacity: 0;
}

.img-frame {
  border: 7px inset #ecebd4;
}

.shadow-movement {
  animation-duration: 2s;
  animation-name: move-shadow;
  /* animation-iteration-count: 1; */
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
}

@keyframes move-shadow {
  0% {
    /* box-shadow: 3px 3px 3px 3px rgba(216, 180, 20, 0.35), */
      /* 1px 1px 3px 3px rgba(10, 50, 179, 0.58); */
    transform: scale(1, 1);
    top: 0;
  }

  50% {
    /* box-shadow: 3px 3px 30px 0px rgba(216, 180, 20, 0.35), */
      /* 1px 1px 40px 0px rgba(10, 50, 179, 0.58); */
    transform: scale(1.2, 1.2);
    top: 10%;
  }

  100% {
    /* box-shadow: 3px 3px 3px 3px rgba(216, 180, 20, 0.35), */
      /* 1px 1px 3px 3px rgba(10, 50, 179, 0.58); */
    transform: scale(1, 1);
    top: 0;
  }

}

.mobileBottom {
  border-top: 1px solid #ffeac3;
  background-color: white;
  position: fixed;
  bottom: 0px;
  height: 60px;
  width: 94%;
  z-index: 8;
}
.randomizer-chip:hover {
  cursor: initial;
}
.randomizer-chip:hover {
  cursor: initial;
}
.avatar-panel-container {
  animation-duration: .75s;
  animation-name: fade-avatar-panel;
  animation-fill-mode: backwards;
}
@keyframes fade-avatar-panel {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
</style>
