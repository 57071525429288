<template>
  <v-card-actions class="py-0">
    <v-chip-group show-arrows column>
        <div v-for="(item, i) in steps">
          <v-chip
            class="nav-noclick"
            :key="i"
            :color="(subStep.id_step >= item.id) ? 'green darken-2' : 'gray'"
            :text-color="(subStep.id_step >= item.id) ? 'white' : 'gray'"
            :disabled="!(subStep.id_step == item.id)"
            :ripple="false"
          >
            CASE {{ i+1 }}
          </v-chip>
          <v-chip
            class="nav-noclick"
            v-if="subStep.id_step === item.id"
            color="green darken-2"
            text-color="white"
            :ripple="false"
            small
          >
            {{ subStep.label }}
          </v-chip>
        </div>
        <div>
      <v-chip
        :color="libraryAndCreditActives ? 'green darken-2' : 'gray'"
        :text-color="libraryAndCreditActives ? 'white' : 'gray'"
        :disabled="!libraryAndCreditActives"
        :ripple="!libraryAndCreditActives"
        @click="showLibrary()"
      >
        LIBRARY
      </v-chip>
      <v-chip
        :color="libraryAndCreditActives ? 'green darken-2' : 'gray'"
        :text-color="libraryAndCreditActives ? 'white' : 'gray'"
        :disabled="!libraryAndCreditActives"
        :ripple="!libraryAndCreditActives"
        @click="showCredits()"
      >
        CLAIM CREDIT
      </v-chip>
    </div>
    </v-chip-group>
  </v-card-actions>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "Stepper",
  props: {
    step: {
      type: Object,
      required: true,
    },
    subStep: {
      type: Object,
      required: true,
    },
    steps: {
      type: Array,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
      default: 1,
    },
  },
  computed: {
    libraryAndCreditActives() {
      if (this.step.id_case === 1) {
        // DIABETESCONFIG
        //return this.currentStep > 1 ? true : false;
        // MASH
        return (this.subStep.id === 20902 || this.currentStep == 3) ? true : false;
      } else if (this.step.id_case === 2) {
        // OBESITYCONFIG
        return (this.subStep.id === 98 || this.currentStep == 5 || this.currentStep == 6) ? true : false;
      } else if (this.step.id_case === 3) {
        // T2D_ObesityCONFIG
        return (this.subStep.id === 19999 || this.currentStep >= 8) ? true : false;
      }
      return true;
    },
  },
  methods: {
    ...mapMutations(["setShowCredit", "setShowLibrary"]),
    showCredits() {
      this.setShowCredit(true);
    },
    showLibrary() {
      this.setShowLibrary(true);
    },
  },
};
</script>
<style>
  .bottom-stepper button {
    color: #ffffff !important;
  }
  .nav-noclick {
    cursor: initial;
  }
</style>