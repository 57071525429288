<template>
  <step-template :subStep="subStep">
    <v-card-text>
      <div>
        <br />
        <h4 v-if="_useRandomizer === false" class="pb-15 text-center" v-html="description">
        </h4>
        <h3 v-else class="pt-5 pb-7 text-center">
          The
          <span style="color:#ff3f3f;"><b>RANDOMIZER</b></span>
          has selected:
          <br />
          <span class="randomizer-value pt-3">{{ _name }}: {{ tempValue }}<span v-html="unit"></span></span>
        </h3>
        <br />
        <p class="mr-5 ml-5">
          <v-slider
            v-model="tempValue"
            :min="_min"
            :max="_max"
            :step="_stepSize" 
            persistent-hint
            thumb-label="always"
            color="blue"
            ticks
            :disabled="_useRandomizer"
            @change="sliderChanged"
          ></v-slider>
          <v-range-slider
            class="slider-scale"
            :tick-labels="cThickLabels"
            :step="cThickLabelsStep"
            :min="_min"
            :max="_max"
            :disabled="true"
          >
          </v-range-slider>
        </p>
        <v-row>
          <v-col v-if="imgSrc" cols="6" class="slider-image d-flex justify-center items-center">
              <img :src="imgSrc" class="pl-8" />
          </v-col>
          <v-col cols="6" class="d-flex">
            <h2 v-if="_useRandomizer === false && firstSet" class="pl-8">
              <span style="font-size:1.1em;margin-bottom:20px;">Selected {{ _name }}:</span>
                <br />
              <span style="font-size:1.3em;margin-bottom:20px;">{{ sliderValue }}<span v-html="unit"></span></span>
            </h2>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </step-template>
</template>

<script>
/**
 * To use:
 - in the database, go to table substeps
 - find the substep row that you want this component
 - update the row field component='step-slider'
 - update the row field component_props as a JSON like:
{
  "variables": { "vmodel": "CUSTOM_VARIABLE_NAME", "CUSTOM_VARIABLE_NAME": CUSTOM_VARIABLE_DEFAULT_VALUE },
  "min":"4",
  "max":"12",
  "description": "Select your patient's A1C by moving to the selected value."
  "imgSrc": "/img/A1C.95cb81e4.jpg"
}
*/

import { mapState, mapActions, mapMutations } from "vuex";
import StepTemplate from "/src/components/steps/StepTemplate.vue";

export default {
  name: "StepSlider",
  components: {
    StepTemplate,
  },
  props: {
    step: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    subStep: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    variables: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    imgSrc: {
      type: String,
      required: false,
      default: "",
    },
    description: {
      type: String,
      required: false,
      default: "",
    },
    min: {
      type: String,
      required: false,
      default: "0",
    },
    max: {
      type: String,
      required: false,
      default: "100",
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
    unit: {
      type: String,
      required: false,
      default: "",
    },
    stepSize: {
      type: String,
      required: false,
      default: "0.1",
    },
    showSteps: {
      type: Boolean,
      default: true,
      required: false,
    },
    supportRandomizer: {
      type: Boolean,
      default: true,
      required: false,
    },
    setDefaultAtAvatar: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      sliderValue: 0,
      tempValue: 0,
      firstSet: false,
      avatarOverridedVariables: {
        min: null,
        max: null,
        vModelValue: null,
        defaultValue: null
      },
      avatarVuexVariables: [],
    };
  },
  computed: {
    ...mapState({
      avatar: (state) => state.avatar,
      caseData: (state) => state.caseData,
    }),
    avatarVariables() {
      if (JSON.parse(this.avatar.variables)) {
        return JSON.parse(this.avatar.variables);
      }
      return null;
    },
    _min() {
      return this.avatarOverridedVariables.min
        ? this.avatarOverridedVariables.min
        : this.min;
    },
    _max() {
      return this.avatarOverridedVariables.max
        ? this.avatarOverridedVariables.max
        : this.max;
    },
    _stepSize() {
      return this.avatarOverridedVariables.stepSize
        ? this.avatarOverridedVariables.stepSize
        : this.stepSize;
    },    
    _name() {
      return this.name
        ? this.name
        : this.variables.vmodel.toUpperCase();
    },
    _useRandomizer() {
      return this.supportRandomizer
        ? this.avatar.random
        : false;
    },
    cThickLabels() {
      let labels = [this._min];
      if (this.showSteps) {
        let stepSizeValue = this._stepSize;
        labels.push(this.roundLabelNumber(parseFloat(this._min) + parseFloat(this.cThickLabelsStep) ,stepSizeValue));
        labels.push(this.roundLabelNumber(parseFloat(this._min) + parseFloat(this.cThickLabelsStep) * 2,stepSizeValue));
        labels.push(this.roundLabelNumber(parseFloat(this._min) + parseFloat(this.cThickLabelsStep) * 3,stepSizeValue));
        labels.push(this._max);
      return labels;
    } else {
      labels.push(this._max);
      return labels;
    }

    },
    cThickLabelsStep() {
      if (this.showSteps) {
        return (this._max - this._min) / 4;
      } else {
        return this._max - this._min;
      }
    },
  },
  watch: {
    sliderValue(val) {
      if (this._useRandomizer) {
        this.firstSet = true;
        this.setSliderValue(val);
        this.manageContinueButton();
      } else {
      this.manageContinueButton();
     }

    },
  },
  created() {
    this.manageContinueButton();
    this.setAvatarOverridedVariables();
    this.setInitialSliderValue();
  },
  methods: {
    ...mapMutations(["setContinueButtonEnabled"]),
    ...mapActions(["setCaseDataValue"]),
    manageContinueButton() {
      if (this.sliderValue === 0) this.setContinueButtonEnabled(false);
      if (!this.firstSet) this.setContinueButtonEnabled(false);
      else this.setContinueButtonEnabled(true);
    },
    roundLabelNumber(num, stepSize) {
      return Math.round(Math.round(num / stepSize) * stepSize);
    },
    setInitialSliderValue() {
      // Try to set currentStorageValue to CaseData
      const currentStorageValue = this.caseData[this.variables.vmodel];
      if (this._useRandomizer) {
        // Avatar Default Value
        const val = this.avatarOverridedVariables.vModelValue
        this.sliderValue = val;
        this.tempValue = val;
        this.firstSet = true;
      } else if (currentStorageValue) {
        // CaseData Storage
        this.sliderValue = currentStorageValue;
        this.tempValue = currentStorageValue;
        this.firstSet = true;
      } else if (this.avatarOverridedVariables.defaultValue) {
        // Avatar Default Value
        const val = this.avatarOverridedVariables.defaultValue
        this.sliderValue = val;
        this.tempValue = val;
      } else {
        // Substep Default Value
        const val = this.variables[this.variables.vmodel]
        this.sliderValue = val;
        this.tempValue = val;
      }
      this.manageContinueButton();
    },
    setSliderValue(val) {
      this.sliderValue = val;
      this.setCaseDataValue({ key: this.variables.vmodel, value: val, randomizer: this.avatar.random });
      this.setAvatarVuexVariablesValues(val);
    },
    sliderChanged(val) {
      this.firstSet = true;
      this.setSliderValue(val);
    },
    setAvatarOverridedVariables() {
      if (!this.avatarVariables || !this.avatarVariables.override) {
        return;
      }
      const override = this.avatarVariables.override;
      for (const i in override) {
        if (override[i].subStep && override[i].subStep.id === this.subStep.id) {
          if (override[i].subStep.addVuexVariable)
            this.addAvatarVuexVariable(override[i].subStep.addVuexVariable);
          if (override[i].subStep.props)
            this.addAvatarOverridedVariables(override[i].subStep.props);
        }
      }
    },
    addAvatarOverridedVariables(overrideProps) {
      for (const key in overrideProps) {
        this.avatarOverridedVariables[key] = overrideProps[key];
      }
    },
    addAvatarVuexVariable(newVuexVariable) {
      for (const i in newVuexVariable) {
        this.avatarVuexVariables.push(newVuexVariable[i]);
        const varName = newVuexVariable[i].name;

        if (!this.caseData[varName]) {
          let firstValue = newVuexVariable[i].value;
          if (typeof firstValue === "object") {
            firstValue = Object.keys(firstValue)[0];
          }

          // this.setCaseDataValue({
          //   key: varName,
          //   value: firstValue,
          //   randomizer: this.avatar.random,
          // });
        }
      }
    },
    setAvatarVuexVariablesValues(sliderValue) {
      for (const i in this.avatarVuexVariables) {
        const variableObject = this.avatarVuexVariables[i];
        if (variableObject.op === "range") {
          this.setAvatarVuexVariablesValuesRange(sliderValue, variableObject);
        }
      }
    },
    setAvatarVuexVariablesValuesRange(sliderValue, varObj) {
      const valuesObject = varObj.value;
      let valArray = [];
      let value = 999999999999999;

      for (const key in valuesObject) {
        valArray.push(valuesObject[key]);
      }
      valArray = valArray.sort().reverse();

      for (const key in valArray) {
        if (valArray[key] <= sliderValue && valArray[key] <= value) {
          value = valArray[key];
          break;
        }
      }

      for (const i in valuesObject) {
        if (valuesObject[i] === value) {
          value = i;
          break;
        }
      }

      this.setCaseDataValue({
        key: varObj.name,
        value: value,
        randomizer: this.avatar.random,
      });
    },
  },
};
</script>
<style>
.slider-image img {
  max-height:300px;
  max-width:390px;
  width: 100%;
  height: auto;
}
.randomizer-value {
  font-size: 1.5em;
  display: block;
}
.slider-scale {
  opacity: .7
}
</style>