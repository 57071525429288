<template>
  <v-card class="d-flex mt-6">
    <v-card-text>
      <v-row>
        <v-col :cols="`${!isMobile ? 4 : 12}`">
          <v-img
            max-height="350"
            max-width="350"
            src="/images/GLOBAL/LOGO/BYOCS-logo.png"
            class="m-auto"
          ></v-img>
        </v-col>
        <v-col :cols="`${!isMobile ? 8 : 12}`">
          <h4 class="pt-5 pb-8 font-weight-medium">
            You have completed this lesson.
            <br /><br />
            <b>
              You may continue with course requirements for credit.
            </b>
          </h4>
          <template>
            <div class="pb-4">
              <v-alert
                type="info"
                color="#82A9B0"
                icon="mdi-account-voice"
                border="left"
                prominent
                shaped
              >
                Your feedback is important to develop impactful education in the
                future. Please complete the course evaluation and join us for
                future <b>Build Your Own Case Study</b>
                activities.
              </v-alert>
            </div>
          </template>
          <div class="row justify-center">
            <div class="pb-4">
              <v-btn
                outlined
                color="orange"
                :rounded="true"
                class="border mr-8 mt-1"
                @click="returnToSteps"
                >return to case</v-btn
              >
            </div>
            <div class="pb-4">
              <email-notes v-if="hasValidEmail" :user="user"></email-notes>
              <print-notes v-if="user" :user="user" class="ml-2"></print-notes>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import EmailNotes from "/src/components/email/EmailNotes.vue";
import PrintNotes from "/src/components/pdf/PrintNotes.vue";
export default {
  name: "Credit",
  components: {
    EmailNotes,
    PrintNotes,
  },
  props: {
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    }, 
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    hasValidEmail() {
      return String(this.user.email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        );
    }
  },
  beforeMount() {
    this.trackingAction("Credits Panel Opened");
  },
  beforeDestroy() {
    this.trackingAction("Credits Panel Closed");
  },
  methods: {
    ...mapMutations(["setShowCredit"]),
    ...mapActions(["trackingAction"]),
    returnToSteps() {
      this.setShowCredit(false);
    },
  },
};
</script>
