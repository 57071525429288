<template>
  <step-template :subStep="subStep">
    <h3 class="pt-8 pb-sm-8 px-sm-16 text-center">
      <v-row v-if="bannerSrc">
        <v-col>
          <img
            :lazy-src="bannerSrc"
            contain
            :src="bannerSrc"
            style="margin: 0 auto; max-height: 200px;"
          />
        </v-col>
      </v-row>
      <div v-html="message"></div>
    </h3>
    <v-form v-model="formIsValid">
    <v-container class="nameemail-form-container">
      <v-row>
        <v-col
          cols="12"
          md="5"
          offset-md="1"
        >
          <v-text-field
            v-model="firstName"
            :rules="firstNameRules"
            label="Your First Name"
            required
            ref="firstnameField"
            @blur="checkFirstNameValidation"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          <v-text-field
            v-model="lastName"
            :rules="lastNameRules"
            label="Your Last Name"
            required
            ref="lastnameField"
            @blur="checkLastNameValidation"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="10"
          offset-md="1"
        >
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="Your Email Address"
            required
            ref="emailField"
            @blur="checkEmailValidation"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>  </step-template>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import StepTemplate from "/src/components/steps/StepTemplate.vue";

export default {
  name: "StepNameEmail",
  components: {
    StepTemplate,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    step: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    subStep: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    message: {
      type: String,
      required: true,
      default: "Please enter your information below:",
    },
    variables: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    bannerSrc: {
      type: String,
      default: "",
      required: false,
    },
  },
  data() {
    return {
      selected: null,
      formIsValid: false,
      firstName: '',
      lastName: '',
      firstNameRules: [
        value => {
          if (value) return true;
          return 'First Name is required.';
        },
      ],
      lastNameRules: [
        value => {
          if (value) return true;
          return 'Last Name is required.';
        },
      ],      email: '',
      emailRules: [
        value => {
          if (value) return true;
          return 'Email Address is requred.';
        },
        value => {
          if (/.+@.+\..+/.test(value)) return true;
          return 'E-mail must be valid.';
        },
      ],
    };
  },
  watch: {
    formIsValid(isValid) {
      this.setContinueButtonEnabled(isValid);
      // if (isValid) {
      //   this.setCaseDataValue({
      //     key: "FirstName",
      //     value: this.firstName,
      //   });
      //   this.setCaseDataValue({
      //     key: "LastName",
      //     value: this.lastName,
      //   });
      //   this.setCaseDataValue({
      //     key: "Email",
      //     value: this.email,
      //   });
      // }
    }
  },
  computed: {
    ...mapState({
      caseData: (state) => state.caseData,
      user: (state) => state.user,
    }),
  },
  created() {
    this.init();
  },
  methods: {
    ...mapMutations(["setContinueButtonEnabled"]),
    ...mapActions(["setCaseDataValue"]),
    init() {
      this.loadPreviousSelectedChoice();
      this.setContinueButtonEnabled(false);
    },
    checkFirstNameValidation() {
      const isValid = this.$refs.firstnameField.validate();
      if (isValid) {
        this.setCaseDataValue({
          key: "firstName",
          value: this.firstName,
        });
      }
    },
    checkLastNameValidation() {
      const isValid = this.$refs.lastnameField.validate();
      if (isValid) {
        this.setCaseDataValue({
          key: "lastName",
          value: this.lastName,
        });
      }
    },
    checkEmailValidation() {
      const isValid = this.$refs.emailField.validate();
      if (isValid) {
        this.setCaseDataValue({
          key: "email",
          value: this.email,
        });
      }
    },
    manageContinueButton() {
      this.setContinueButtonEnabled(valid);
    },
    select(option) {
      this.setCaseDataValue({
        key: this.variables.vmodel,
        value: option.value,
        fullObject: option,
      });
      this.setContinueButtonEnabled(true);
    },
    loadPreviousSelectedChoice() {
      if (this.caseData.firstName) {
        this.firstName = this.caseData.firstName;
      }
      if (this.caseData.lastName) {
        this.lastName = this.caseData.lastName;
      }
      if (this.caseData.email) {
        this.email = this.caseData.email;
      }
    },
  },
};
</script>
<style>
  .nameemail-form-container {
    padding: 0 30px 80px;
  }
  .nameemail-form-container input {
    font-size: 20px;
  }
</style>
