<template>
  <step-template :subStep="subStep">
    <v-card-text>
      <v-row>
        <v-row v-if="bannerSrc">
          <v-col class="text-center">
            <img
              :lazy-src="bannerSrc"
              contain
              :src="bannerSrc"
              style="margin: 0 auto;"
            />
          </v-col>
        </v-row>
        <v-row v-if="title" style="width: 100%;">
          <v-col
            class="mx-8 mt-5 mb-0"
          >
            <h3 class="text-center text-break" v-html="title"></h3>
          </v-col>
        </v-row>
        <v-row v-if="messageAbove" style="width: 100%;">
          <v-col>
            <h5 
              class="text-center text-break" 
              v-html="messageAbove"
              >
            </h5>
          </v-col>
        </v-row>
        <v-row v-if="messageBox" style="width: 100%;">
          <v-col>
            <v-sheet
            class="pa-6 mx-8 mt-5 mb-0"
            elevation="3"
            rounded
            :color=messageBoxBgColor
          >
            <h5 
              class="text-center text-break ma-0" 
              v-html="messageBox"
              :style="`color:${messageBoxTxtColor}`"
              >
            </h5>
          </v-sheet>
          </v-col>
        </v-row>
        <v-row v-if="messageBelow" style="width: 100%;">
          <v-col>
            <h5 
              class="text-center text-break mx-8 mt-5 mb-0" 
              v-html="messageBelow"
              >
            </h5>
          </v-col>
        </v-row>
        <v-row v-if="showContinueMessage" style="width: 100%;">
          <v-col>
            <h5 
              class="text-center text-break mx-8 mt-5 mb-0" 
              >
              Click <span style='color:#ff9800;'><b>CONTINUE</b></span> to proceed.
            </h5>
          </v-col>
        </v-row>

      </v-row>
    </v-card-text>
  </step-template>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import StepTemplate from "/src/components/steps/StepTemplate.vue";
import { controllers } from "chart.js";

export default {
  name: "StepNotification",
  components: {
    StepTemplate,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    step: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    subStep: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    variables: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    bannerSrc: {
      type: String,
      default: "",
      required: false,
    },
    title: {
      type: String,
      default: "",
      required: false,
    },
    messageAbove: {
      type: String,
      required: false,
      default: "",
    },
    messageBox: {
      type: String,
      required: false,
      default: "",
    },
    messageBoxBgColor: {
      type: String,
      required: false,
      default: "#f0faff",
    },
    messageBoxTxtColor: {
      type: String,
      required: false,
      default: "#304156",
    },
    messageBelow: {
      type: String,
      required: false,
      default: "",
    },
    showContinueMessage: {
      type: Boolean,
      required: false,
      default: true,
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      avatar: (state) => state.avatar,
      caseData: (state) => state.caseData,
      state: (state) => state,
    })
  },
  created() {
    this.init();
    
  },
  methods: {
    ...mapMutations(["setContinueButtonEnabled", "setForceNextSubStepTo"]),
    ...mapActions(["setCaseDataValue"]),
    init() {
      this.setContinueButtonEnabled(true);
    },
    getButtonLabelStyle(style) {
      let s =
        "object-fit: contain !important; max-width: 100% !important; max-height: 100% !important;";
      if (style) {
        s += style;
        return s;
      } else if (this.buttonsStyle) {
        s += this.buttonsStyle;
        return s;
      }
      return "";
    },
    getButtonStyle(style) {
      let s =
        "object-fit: contain !important; max-width: 100% !important; max-height: 100% !important; width: auto !important; height: auto !important; max-width: inherit !important;";
      if (style) {
        s += style;
        return s;
      } else if (this.buttonsStyle) {
        s += this.buttonsStyle;
        return s;
      }

      return `background-color:white;border:1px solid ${this.borderColor}; border-radius: 15px; ${s}`;
    }
  },
};
</script>
<style>
</style>