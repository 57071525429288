<template>
  <v-row v-if="!validatedUser"
    >Opps, something went wrong! <br />Try to contact administration.</v-row
  >
  <v-row v-else-if="!(contentLoaded && loading.caseProgress)">
    <Loading class="d-flex align-center mx-5" />
  </v-row>
  <div v-else-if="showCredit">
    <credit :isMobile="isMobile()" />
  </div>
  <div v-else-if="showLibrary">
    <library
      :steps="steps"
      :step="step"
      :subStep="subStep"
      :videos="videos"
      :isMobile="isMobile()"
      :avatars="avatars"
    />
  </div>
  <div v-else>
    <v-row>
      <v-col
        cols="12"
        :md="dynamicComponentWidth"
        :class="cMainContent"
      >
        <component
          v-if="subStep"
          :key="dynamicComponentKey"
          :is="subStep.component"
          :steps="steps"
          :step="step"
          :subStep="subStep"
          :videos="videos"
          :avatars="avatars"
          :avatarsCombinations="avatars_combinations"
          :isMobile="isMobile()"
          v-bind="componentProps"
        ></component>
      </v-col>
      <avatar-panel
        v-show="avatarPanelVisible"
        :steps="steps"
        :step="step"
        :subStep="subStep"
        :subStepOrderMap="subStepOrderMap"
        :isMobile="isMobile()"
      ></avatar-panel>
      <avatar-panel-blank
        v-show="avatarPanelBlankVisible"
      ></avatar-panel-blank>
    </v-row>
    <v-row v-show="!introduction" class="overflow-x-hidden mx-1">
      <v-col v-if="!isMobile()" cols="12" class="d-flex justify-center">
        <stepper
          :steps="steps"
          :step="step"
          :subStep="subStep"
          :currentStep="currentStep"
        ></stepper>
      </v-col>
      <stepper
        v-else
        :steps="steps"
        :step="step"
        :subStep="subStep"
        :currentStep="currentStep"
        class="pb-12"
      ></stepper>
    </v-row>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import {
  LS_KEY_UUID,
  LS_KEY_AVATAR,
  LS_KEY_CASE_DATA,
  LS_CURRENT_CASE,
  LS_CURRENT_STEP,
  LS_CURRENT_SUBSTEP,
  LS_SHOW_CAPTIONS,
} from "/src/store/index.js";
import axios from "axios";
import AvatarPanel from "/src/components/steps/AvatarPanel.vue";
import AvatarPanelBlank from "/src/components/steps/AvatarPanelBlank.vue";
import Credit from "/src/components/card/Credit.vue";
import Library from "/src/components/card/Library.vue";
import Loading from "/src/components/base/Loading.vue";
import Stepper from "/src/components/steps/Stepper.vue";
import StepAvatar from "/src/components/steps/StepAvatar.vue";
import StepAvatarMap from "/src/components/steps/StepAvatarMap.vue";
import StepButtons from "/src/components/steps/StepButtons.vue";
import StepFork from "/src/components/steps/StepFork.vue";
import StepMultipleChoice from "/src/components/steps/StepMultipleChoice.vue";
import StepProfile from "/src/components/steps/StepProfile.vue";
import StepProfileV2 from "/src/components/steps/StepProfileV2.vue";
import StepProfileV3 from "/src/components/steps/StepProfileV3.vue";
import StepProfileV4 from "/src/components/steps/StepProfileV4.vue";
import StepBmiWaist from "/src/components/steps/StepBmiWaist.vue";
import StepSlider from "/src/components/steps/StepSlider.vue";
import StepVideo from "/src/components/steps/StepVideo.vue";
import StepNameEmail from "/src/components/steps/StepNameEmail.vue";
import StepNotification from "/src/components/steps/StepNotification.vue";
import StepFib4 from "/src/components/steps/StepFib4.vue";
import StepVcte from "/src/components/steps/StepVcte.vue";
import StepElf from "/src/components/steps/StepElf.vue";

const ALLOWED_PLATFORMS = ["mli", "healio", "lms", "nexus", "mycme", "test"];

export default {
  name: "Case",
  metaInfo: {
    title: "Medical Learning Institute, Inc.",
  },
  components: {
    AvatarPanel,
    AvatarPanelBlank,
    Credit,
    Library,
    Loading,
    Stepper,
    StepAvatar,
    StepAvatarMap,
    StepButtons,
    StepFork,
    StepMultipleChoice,
    StepProfile,
    StepProfileV2,
    StepProfileV3,
    StepProfileV4,
    StepBmiWaist,
    StepSlider,
    StepVideo,
    StepNameEmail,
    StepNotification,
    StepFib4,
    StepVcte,
    StepElf
  },
  data() {
    return {
      loading: {
        steps: false,
        videos: false,
        avatar: false,
        avatar_combinations: false,
        caseProgress: false,
      },
      validatedUser: true,
      componentProps: null,
      steps: [],
      videos: [],
      avatars: [],
      avatars_combinations: [],
      subStepOrderMap: {
        idToOrder: {},
        orderToId: {},
      },
    };
  },
  computed: {
    ...mapState({
      avatar: (state) => state.avatar,
      introduction: (state) => state.introduction,
      case: (state) => state.case,
      currentStep: (state) => state.currentStep,
      currentSubStep: (state) => state.currentSubStep,
      showCredit: (state) => state.showCredit,
      showLibrary: (state) => state.showLibrary,
      user: (state) => state.user,
    }),
    contentLoaded() {
      return !(
        this.loading.steps &&
        this.loading.videos &&
        this.loading.avatar &&
        this.loading.avatar_combinations
      );
    },
    step() {
      if (this.steps.length < 1) {
        return null;
      }
      return this.steps.find((val) => {
        if (val.id === this.currentStep) {
          return val;
        }
      });
    },
    subStep() {
      if (!this.step) {
        return null;
      }
      let substep = this.getSubStepById(this.currentSubStep);
      if (!substep) {
        substep = this.getFirstSubStep();
      }
      return substep;
    },
    avatarPanelVisible() {
      return !!this.avatar.name;
    },
    avatarPanelBlankVisible() {
      return (!this.avatar.name && !this.introduction);
    },
    dynamicComponentWidth() {
      return this.introduction ? 12 : 8;
    },
    dynamicComponentKey() {
      return this.currentStep + "-" + this.currentSubStep;
    },
    cMainContent() {
      if (this.isMobile()) return "fit-content";
      else return "";
    },
  },
  watch: {
    subStep(val) {
      if (val) {
        this.loadSubStepComponentProps();
        this.trackAction(val);
      }
    },
    contentLoaded(val) {
      if (val) {
        this.loadProgress();
      }
    },
  },
  async created() {
    this.setupUser();
    this.validatedUser = this.isValidUser();
    if (this.validatedUser) {
      this.getSteps();
      this.getAvatar();
      this.getAvatarCombinations();
      this.getVideos();
    }
  },
  async mounted() {
    this.trackingAction("init");
  },
  methods: {
    ...mapMutations([
      "setAvatar",
      "setCaseValue",
      "setCurrentStep",
      "setCurrentSubStep",
      "setUser",
      "setShowCaptions"
    ]),
    ...mapActions(["trackingAction", "setCaseData", "pushCaseData"]),
    setupUser() {
      this.setCaseValue({ key: "name", value: this.$route.params.case });
      this.setUser({
        uuid: this.UuidDefaultSet(),
        fName: this.urlParamDefaultSet(this.$route.params.fname, "FirstNameNotSet"),
        lName: this.urlParamDefaultSet(this.$route.params.lname, "LastNameNotSet"),
        email: this.urlParamDefaultSet(this.$route.params.email, "EmailNotSet"),
        platform: this.$route.params.platform,
        learnerType: this.urlParamDefaultSet(this.$route.params.learnertype, "LearnerTypeNotSet"),
        idSent: this.idSent()
      });
    },
    urlParamDefaultSet(route, defaultValue) {
      if(route === undefined){
        return defaultValue;
      }
      return route;
    },
    isValidUser() {
      let valid = true;
      /*
      if (ALLOWED_PLATFORMS.indexOf(this.user.platform) === -1) {
        this.trackingAction(
          `User validation error: tried to access with invalid platform (${this.user.platform})`
        );
        valid = false;
      }
      */

      // System will accept a UUID or Numeric Value
      /*
      const regex = /^[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}$/;
      if (!regex.test(this.$route.params.uuid) && isNaN(this.$route.params.uuid)) {
          this.trackingAction(
            `User validation error: tried to access with invalid uuid (${this.$route.params.uuid})`
          );
          valid = false;
      }
      */

      return valid;
    },
    idSent() {
      if (this.$route.params.uuid === undefined) {
        return false
      }
      return true;
    },
    UuidDefaultSet() {
      
      var uuid = "";

      if (this.$route.params.uuid === undefined) {

        if (localStorage.getItem(LS_KEY_UUID)) {

          // Use Local Storage UUID
          uuid = localStorage.getItem(LS_KEY_UUID);
          
        } else {

          // Create a UUID from DATE, TIME and RANDOM NUMBER
          var date = new Date();
          // Get year, month, day, etc. part from the date
          var year = date.toLocaleString("default", { year: "numeric", timeZone: 'UTC' });
          var month = date.toLocaleString("default", { month: "2-digit", timeZone: 'UTC' });
          var day = date.toLocaleString("default", { day: "2-digit", timeZone: 'UTC' });
          var hours = date.getHours().toLocaleString("default", { hour: "2-digit", timeZone: 'UTC' });
          var minutes = date.getMinutes().toLocaleString("default", { minute: "2-digit", timeZone: 'UTC' });
          var minTwoDigit = minutes < 10 ? '0' + minutes : minutes;
          var seconds = date.getSeconds();
          var secTwoDigit = seconds < 10 ? '0' + seconds : seconds;
          var secTwoDigit = secTwoDigit + "00";
          var milliseconds = date.getMilliseconds().toLocaleString("default", { fractionalSecondDigits: 3 });
          var msThreeDigit = milliseconds.toString().padStart(3, '0') + "0";
          let fiveDigitRandomNumber = Math.floor(100000000000 + Math.random() * 900000000000);

          // platform-00000000-0000-0000-0000-000000000000
          uuid = this.$route.params.platform + "-" + 
            year + month + day + "-" + hours + minTwoDigit + "-" + 
            secTwoDigit + "-" + msThreeDigit + "-" + fiveDigitRandomNumber;

        }

      } else {
        uuid = this.$route.params.uuid;
        // If UUID is a numeric value, append the platform to the UUID
        const regex = /^[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}$/;
        if (regex.test(uuid)) {
          return uuid;
        } else {
          uuid = this.$route.params.platform + "-" + uuid;
        }
      }

      return uuid;
    },
    urlParamDefaultSet(route, defaultValue) {
      if(route === undefined){
        return defaultValue;
      }
      return route;
    },
    sortSubstepsByOrderField(steps) {
      for (const i in steps) {
        const step = steps[i];
        const substeps = step.sub_steps;
        // Map ID and Order to faster navigation for 'continue' and 'back' buttons
        for (const k in substeps) {
          const substep = substeps[k];
          if (!this.subStepOrderMap.idToOrder[step.id]) {
            this.subStepOrderMap.idToOrder[step.id] = {};
          }
          this.subStepOrderMap.idToOrder[step.id][substep.id] = substep.order;

          if (!this.subStepOrderMap.orderToId[step.id]) {
            this.subStepOrderMap.orderToId[step.id] = {};
          }
          this.subStepOrderMap.orderToId[step.id][substep.order] = substep.id;
        }
        // Sort by order
        substeps.sort(function(a, b) {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        });
      }
      return steps;
    },
    async getSteps() {
      const caseName = this.case.name;
      this.loading.steps = true;
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_ENDPOINT}/data/${caseName}`
        );

        this.steps = this.sortSubstepsByOrderField(response.data);
      } catch (err) {
        console.log(err);
      }
      this.loading.steps = false;
    },
    async getAvatar() {
      const caseName = this.case.name;
      this.loading.avatar = true;
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_ENDPOINT}/avatars/${caseName}`
        );
        this.avatars = response.data;
      } catch (err) {
        console.log(err);
      }
      this.loading.avatar = false;
    },
    async getAvatarCombinations() {
      const caseName = this.case.name;
      this.loading.avatar_combinations = true;
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_ENDPOINT}/avatar_combinations/${caseName}`
        );
        // Clear empty keys and parse variables to a object
        for (const i in response.data) {
          response.data[i] = Object.fromEntries(
            Object.entries(response.data[i]).filter(([_, v]) => v != null)
          );
          if (response.data[i].variables) {
            response.data[i].variables = JSON.parse(response.data[i].variables);
          }
        }
        this.avatars_combinations = response.data;
      } catch (err) {
        console.log(err);
      }
      this.loading.avatar_combinations = false;
    },
    async getVideos() {
      this.loading.videos = true;
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_ENDPOINT}/videos`
        );
        this.videos = response.data.data;
      } catch (err) {
        console.log(err);
      }
      this.loading.videos = false;
    },
    loadSubStepComponentProps() {
      if (this.subStep.component_props) {
        this.componentProps = JSON.parse(this.subStep.component_props);
      } else {
        this.componentProps = {};
      }
    },
    /**
     * First restore caseData from localStorage, after request an update from API
     */
    async restoreCaseData() {
      let localStoredCaseData = localStorage.getItem(LS_KEY_CASE_DATA);
      if (localStoredCaseData) {
        localStoredCaseData = JSON.parse(localStoredCaseData);
        this.setCaseData(localStoredCaseData);
        this.setShowCaptions(localStorage.getItem(LS_SHOW_CAPTIONS));
      }

      try {
        if (!this.case.id) return;
        const response = await axios.get(
          `${process.env.VUE_APP_API_ENDPOINT}/case-data/${this.user.uuid}/${this.case.id}`
        );

        if (response && response.status === 200 && response.data) {
          const caseData = response.data;
          if (caseData.uuid === this.user.uuid) {
            this.setCurrentStep(caseData.step);
            this.setCurrentSubStep(caseData.substep);
            this.setAvatar(JSON.parse(caseData.avatar));
            this.setCaseData(JSON.parse(caseData.case_data));
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async loadProgress() {
      if (!this.steps[0]) {
        setTimeout(() => {
          this.loadProgress();
        }, 200);
        return;
      }

      const lastUuid = localStorage.getItem(LS_KEY_UUID);
      const lastCase = JSON.parse(localStorage.getItem(LS_CURRENT_CASE));
      const currentCaseId = this.steps[0].id_case;
      if (
        !lastUuid ||
        lastUuid !== this.user.uuid ||
        !lastCase ||
        lastCase.id !== currentCaseId
      ) {
        // User changed, flush local stored data
        const firstStepId = this.steps[0].id;
        const firstSubStepId = this.steps[0].sub_steps[0].id;
        this.setCurrentStep(firstStepId);
        this.setCurrentSubStep(firstSubStepId);
        this.setAvatar({});
        this.setCaseData({});
        localStorage.setItem(LS_KEY_UUID, this.user.uuid);
      }

      const caseId = this.steps[0].id_case;
      this.setCaseValue({ key: "id", value: caseId });

      if (localStorage.getItem(LS_CURRENT_STEP)) {
        const val = parseInt(localStorage.getItem(LS_CURRENT_STEP));
        this.setCurrentStep(val);
      }

      if (localStorage.getItem(LS_CURRENT_SUBSTEP)) {
        this.setCurrentSubStep(
          parseInt(localStorage.getItem(LS_CURRENT_SUBSTEP))
        );
      }

      let localStoredAvatar = localStorage.getItem(LS_KEY_AVATAR);
      if (localStoredAvatar) {
        localStoredAvatar = JSON.parse(localStoredAvatar);
        this.setAvatar(localStoredAvatar);
      }

      await this.restoreCaseData();

      setTimeout(() => {
        this.loading.caseProgress = true;
      }, 100);
    },
    trackAction(subStep) {
      this.trackingAction(`Loaded SubStep: ${subStep.id}`);
      this.pushCaseData();
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
    getSubStepById(subStepId) {
      return this.step.sub_steps.find((val) => {
        if (val.id === subStepId) {
          return val;
        }
      });
    },
    getFirstSubStep() {
      this.setCurrentSubStep(this.step.sub_steps[0].id);
      return this.step.sub_steps[0];
    },
  },
};
</script>

<style>
.fit-content {
  min-width: fit-content;
}
</style>
