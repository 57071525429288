<template>
  <step-template :subStep="subStep">
    <v-card-text>
      <v-row>
        <v-row v-if="bannerSrc">
          <v-col>
            <img
              :lazy-src="bannerSrc"
              contain
              :src="bannerSrc"
              style="margin: 0 auto;"
            />
          </v-col>
        </v-row>
        <v-row v-if="title" style="width: 100%;">
          <v-col>
            <h3 class="pb-5 px-2 text-center text-break" v-html="title"></h3>
          </v-col>
        </v-row>
        <v-row class="px-2 px-sm-5 justify-center">
          <v-col
            :cols="computedCols"
            v-for="button in _buttons"
            class="d-flex justify-center"
            style="max-width:250px;max-height:300px;"
          >
            <v-card outlined tile color="transparent">
              <img
                v-if="button.url && !useRandomizer"
                :class="{'button-img-active' : activeButton(button)}"
                class="button-img img-fluid"
                :style="getButtonStyle(button.style)"
                contain
                :src="button.url"
                @click="select(button, $event)"
              />
              <img
                v-else-if="button.url && useRandomizer"
                :class="{'button-img-active' : activeButton(button)}"
                class="button-img img-fluid button-img-disabled"
                :style="getButtonStyle(button.style)"
                contain
                :src="button.url"
                :disabled=true
              />
              <v-btn
                v-else-if="!button.url && !useRandomizer"
                color="warning"
                rounded
                large
                :class="{'button-btn-active' : activeButton(button)}"
                class="button-btn"
                :style="getButtonLabelStyle(button.style)"
                @click="select(button, $event)"
              >
                {{ button.name }}
              </v-btn>
              <v-btn
                v-else
                color="warning"
                rounded
                large
                class="button-btn button-btn-disabled"
                :disabled=!activeButton(button)
                :style="getButtonLabelStyle(button.style)"
              >
                {{ button.name }}
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-row>
      <v-row v-if="!useRandomizer">
        <v-col v-if="selectedButton" class="mt-10 d-flex text-center justify-center">
          <h2><small>You <span v-if="previouslyTag">previously</span> selected:</small><br><span style="font-size:1.1em;margin-bottom:20px;">{{ selectedButton.name }}</span></h2>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col class="mt-10 d-flex text-center justify-center">
          <h2>
            <small>The
            <span style="color:#ff3f3f;"><b>RANDOMIZER</b></span>
            has selected:</small><br><span style="font-size:1.1em;margin-bottom:20px;">{{ selectedButton.name }}</span>
          </h2>
        </v-col>
      </v-row>
    </v-card-text>
  </step-template>
</template>

<script>
/**
 * To use:
 - in the database, go to table substeps
 - find the substep row that you want this component
 - update the row field component='step-buttons'
 - update the row field component_props as a JSON like:
{
  "variables": { "vmodel": "CUSTOM_VARIABLE_NAME" },
  "buttons": [
    { "value": "CUSTOM_VARIABLE_VALUE1", "name": "CVD", "url": "/img/Comorbidity_CVD.4f230a8d.jpg" },
    { "value": "CUSTOM_VARIABLE_VALUE2", "name": "CKD", "url": "/img/Comorbidity_CKD.bfaecdfc.jpg" },
    {
      "value": "CUSTOM_VARIABLE_VALUE3",
      "name": "PCOS",
      "url": "/img/Comorbidity_PCOS.e12a30a2.jpg",
      "style": "background-color: #17a8de; border-radius: 25px;",
      "hide": { "avatar.gender": ["Male", "Transfemale"] }
    },
    OR
    { "value": true, "name": "Yes", "forceNextSubStepTo": 17 },
    { "value": false, "name": "No" }
  ],
  "borderColor":"green",
  "buttonsStyle": "",
  "bannerSrc": "/img/sdoh.487f9d98.jpg",
  "title": "Please select a Social Determinant of Health to explore.",
  "useRandomizer": true / false, (AS BOOLEAN)
}

NOTE: Use useRandomizer requires setting the Avatar override data:
{
    "override": [
        {
            "subStep": {
                "id": STEPNUMBER,
                "props": {
                    "vModelValue": BUTTONVALUE
                }
            }
        }
    ]
}

(only 'variables' and 'buttons' are required)
 */

import { mapState, mapMutations, mapActions } from "vuex";
import StepTemplate from "/src/components/steps/StepTemplate.vue";
import { controllers } from "chart.js";

export default {
  name: "StepNotification",
  components: {
    StepTemplate,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    step: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    subStep: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    variables: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    buttons: {
      type: Array,
      required: false,
      default: null,
    },
    buttonsStyle: {
      type: String,
      required: false,
      default: "",
    },
    borderColor: {
      type: String,
      required: false,
      default: "gray",
    },
    bannerSrc: {
      type: String,
      default: "",
      required: false,
    },
    title: {
      type: String,
      default: "",
      required: false,
    },
    supportRandomizer: {
      type: Boolean,
      default: false,
      required: false,
    },
    randomizeButtonOrder: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      _buttons: [],
      selectedButton: null,
      previouslyTag: true,
      useRandomizer: false,
      avatarOverridedVariables: {
        vModelValue: null,
      }
    };
  },
  computed: {
    ...mapState({
      avatar: (state) => state.avatar,
      caseData: (state) => state.caseData,
      state: (state) => state,
    }),
    computedCols() {
      if (this._buttons.length === 1) {
        // 1 Row
        return 12;
      } else if (this._buttons.length === 2) {
        // 2 Rows
        return 6;
      } else if (this._buttons.length === 3 || this._buttons.length === 5 || this._buttons.length === 6) {
        // 3 Rows
        return 4;
      } else if (this._buttons.length === 4 || this._buttons.length === 7 || this._buttons.length === 8) {
        // 4 Rows
        return 3;
      }
      // 4 Rows
      return 3;
    },
    avatarVariables() {
      if (this.avatar.variables) {
        return JSON.parse(this.avatar.variables);
      }
      return null;
    },
  },
  created() {
    this.init();
    
  },
  methods: {
    ...mapMutations(["setContinueButtonEnabled", "setForceNextSubStepTo"]),
    ...mapActions(["setCaseDataValue"]),
    init() {
      this.setContinueButtonEnabled(false);
      this.initButtons();
      this.loadPreviousSelectedChoice();
      if (this.randomizeButtonOrder && !this.selectedButton) {
        this.randomizeButtons(this._buttons);
      }
      if (this.selectedButton) {
        this.setContinueButtonEnabled(true);
      }
    },
    /**
     * Buttons can be received in specific props or in the substep
     * Props get priority
     */
    initButtons() {
      if (this.buttons) {
        this._buttons = this.filterButtons(this.buttons);
      }
    },
    randomizeButtons(buttonsArray) {
      let currentIndex = buttonsArray.length, randomIndex;
      while (currentIndex > 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [buttonsArray[currentIndex], buttonsArray[randomIndex]] = [
        buttonsArray[randomIndex], buttonsArray[currentIndex]];
      }
      return buttonsArray;
    },
    loadPreviousSelectedChoice() {
      if (this.avatar.random === true && this.supportRandomizer) {
        this.useRandomizer = true;
        this.setAvatarOverridedVariables()
        const avatarValue = this.avatarOverridedVariables.vModelValue
          ? this.avatarOverridedVariables.vModelValue
          : this.variables[this.variables.vmodel];
        this.selectedButton = this._buttons.find((button) => {
          if (button.value == avatarValue) {
            this.setCaseDataValue({
              key: this.variables.vmodel,
              value: button.value,
              fullObject: button,
              randomizer: this.useRandomizer,
            });
            return true;
          }
        });
      } else {
        const currentValue = this.caseData[this.variables.vmodel];
        this.selectedButton = this._buttons.find((button) => {
          if (button.value == currentValue) {
            return true;
          }
        });
      }
      if (this.selectedButton) {
        this.checkForceNextSubStepTo();
      }
    },
    setAvatarOverridedVariables() {
      if (!this.avatarVariables || !this.avatarVariables.override) {
        return;
      }
      const override = this.avatarVariables.override;
      for (const i in override) {
        if (override[i].subStep && override[i].subStep.id === this.subStep.id) {
          if (override[i].subStep.props)
            this.addAvatarOverridedVariables(override[i].subStep.props);
        }
      }
    },
    addAvatarOverridedVariables(overrideProps) {
      for (const key in overrideProps) {
        this.avatarOverridedVariables[key] = overrideProps[key];
      }
    },
    filterButtons(buttons) {
      return buttons.filter((button) => {
        if (button.hide) {
          
          for (const filterVariables in button.hide) {
            const filterVariable = filterVariables.split(".");
            const currentValue = String(this.state[filterVariable[0]][
              filterVariable[1]
            ]);
            if (button.hide[filterVariables].indexOf(String(currentValue)) !== -1) {
              return false;
            }
          }
        }
        return true;
      });
    },
    select(button, $event) {
      this.previouslyTag = false;
      this.setCaseDataValue({
        key: this.variables.vmodel,
        value: button.value,
        fullObject: button,
        randomizer: this.useRandomizer,
      });
      this.selectedButton = button;
      this.checkForceNextSubStepTo();
      this.setContinueButtonEnabled(true);
    },
    activeButton(button) {
      if (this.selectedButton) {
        return this.selectedButton.value === button.value;
      } 

    },
    checkForceNextSubStepTo() {
      if (this.selectedButton && this.selectedButton.forceNextSubStepTo) {
        this.setForceNextSubStepTo(this.selectedButton.forceNextSubStepTo);
      } else {
        this.setForceNextSubStepTo(null);
      }
    },
    getButtonLabelStyle(style) {
      let s =
        "object-fit: contain !important; max-width: 100% !important; max-height: 100% !important;";
      if (style) {
        s += style;
        return s;
      } else if (this.buttonsStyle) {
        s += this.buttonsStyle;
        return s;
      }
      return "";
    },
    getButtonStyle(style) {
      let s =
        "object-fit: contain !important; max-width: 100% !important; max-height: 100% !important; width: auto !important; height: auto !important; max-width: inherit !important;";
      if (style) {
        s += style;
        return s;
      } else if (this.buttonsStyle) {
        s += this.buttonsStyle;
        return s;
      }

      return `background-color:white;border:1px solid ${this.borderColor}; border-radius: 15px; ${s}`;
    }
  },
};
</script>
<style>
  .button-img, .button-btn {
    opacity: 1;
    box-shadow: none;
    transition: transform ease 0.3s, box-shadow ease 0.5s;
  }
  .button-img {
    cursor: pointer; 
  }
  .button-img:active {
    transform: scale(.99);
  }
  .button-img-disabled {
    opacity:.5;
    cursor: initial; 
  }
  .button-img-active, .button-btn-active {
    transform: scale(1.1) translateY(-2px);
    opacity:1;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2) !important;
  }
</style>