<template>
  <step-template :subStep="subStep">
    <!-- 
      Fork component
      This substep only use is to move to another substep
      based on the 'onContinue' and 'onBack' props JSON 
    -->
  </step-template>
</template>

<script>
/**
 * To use:
 - in the database, go to table substeps
 - find the substep row that you want this component
 - update the row field component='step-fork'
 - update the row field component_props as a JSON like:
{
  "onContinue": {
    "variable": "clinicalCaseScenario",
    "action": "changeSubStep",
    "actionValue": {
      "subStepId": "clinicalCaseScenarioValue",
      "1": "70",
      "2": "71",
      "VALUE": "STEP"
    }
  },
  "onBack": {
    "variable": "subStepId",
    "action": "changeSubStep",
    "actionValue": {
      "77": "92",
    }
  }
}
OR
{
  "onContinue": {
    "variable": "clinicalCaseScenario",
    "action": "changeSubStep",
    "actionValue": {
      "1": "70",
      "2": "71",
      "VALUE": "STEP"
    }
  },
  "onBack": { "action": "changeSubStep", "actionValue": { "goto": subStepId } },
}
OR To use Variables in User or Avatar. Also use of "default" if other values don't match
{
  "onContinue": {
	  "dataset": "user",
    "variable": "email",
    "action": "changeSubStep",
    "actionValue": { "EmailNotSet": "10003" , "default": "10005" }
  },
  "onBack": {
    "action": "changeSubStep",
    "actionValue": { "goto": "10001" }
  }
}
*/
import { mapState, mapActions, mapMutations } from "vuex";
import StepTemplate from "/src/components/steps/StepTemplate.vue";

export default {
  name: "StepFork",
  components: {
    StepTemplate,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    step: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    subStep: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    onContinue: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    onBack: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      caseData: (state) => state.caseData,
      avatar: (state) => state.avatar,
      user: (state) => state.user,
      lastNavigationWas: (state) => state.lastNavigationWas,
      lastSubStepIdWas: (state) => state.lastSubStepIdWas,
    }),
  },
  created() {
    if (this.lastNavigationWas === "continue") {
      this.onContinueCall();
    } else if (this.lastNavigationWas === "back") {
      this.onBackCall();
    }
  },
  methods: {
    ...mapMutations(["setForceNextSubStepTo"]),
    ...mapActions(["trackingAction"]),
    onContinueCall() {
      const action = this.onContinue.action;
      if (action === "changeSubStep") {
        if (this.onContinue.actionValue.goto) {
          this.moveToSubStep(parseInt(this.onContinue.actionValue.goto), "Continue");
        } else {
          const dataSet = this.getDataSet(this.onContinue);
          const variableValue = this.getVariableValue(dataSet, this.onContinue.variable);
          this.callChangeSubStep(this.onContinue.actionValue, variableValue, dataSet, "Continue");
        }
      }
    },
    onBackCall() {
      const action = this.onBack.action;
      if (action === "changeSubStep") {
        if (this.onBack.actionValue.goto) {
          this.moveToSubStep(parseInt(this.onBack.actionValue.goto), "Back");
        } else {
          const dataSet = this.getDataSet(this.onBack);
          const variableValue = this.getVariableValue(dataSet, this.onBack.variable);
          this.callChangeSubStep(this.onBack.actionValue, variableValue, dataSet, "Back");
        }
      }
    },
    getDataSet(callback) {
      if (callback.dataset === undefined || callback.dataset === null || callback.dataset == "caseData") {
        return this.caseData;
      } else if (callback.dataset == "user") {
        return this.user;
      } else if (callback.dataset == "avatar") {
        return this.avatar;
      }
    },
    getVariableValue(dataSet, variableName) {
      if (variableName === "lastSubStepIdWas") {
        return this.lastSubStepIdWas;
      }
      return dataSet[variableName];
    },
    callChangeSubStep(obj, variableValue, dataSet, tag) {
      let subStepId = "";

      if (obj[variableValue] === undefined) {
        subStepId = obj.default;
      } else {
        subStepId = obj[variableValue];
      }
      //const subStepId = Object.keys(obj).find(
      //   (key) => obj[key] == variableValue
      // );
      this.moveToSubStep(parseInt(subStepId), tag);
    },
    moveToSubStep(subStepId, tag) {
      this.setForceNextSubStepTo(parseInt(subStepId));
      this.$root.$refs.AvatarPanel.nextSubStep();
      this.trackingAction("Fork " + tag + " to SubStep: " + subStepId);
    },
  },
};
</script>
