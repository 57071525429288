<template>
  <step-template :subStep="subStep">
    <v-card-text>
    <v-row v-if="title" style="width: 100%;">
        <v-col
          class="mx-8 mt-5 mb-0"
        >
          <h3 class="text-center text-break" v-html="title"></h3>
        </v-col>
      </v-row>
      <v-row style="width: 100%;">
          <v-col class="text-center text-break mt-5 mb-0">
            <div class="fib4">
              <table border="0" cellspacing="0" cellpadding="0" width="100%">
                <tr>
                  <td>
                    <div class="fib4-header">Age <em>(years)</em></div>
                  </td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>
                    <div class="fib4-header">AST Level <em>(U/L)</em></div>
                  </td>
                  <td rowspan="5">
                    <div class="fib4-equals">=</div>
                  </td>
                  <td class="fib4-td-top fib4-desktop">
                    <div class="fib4-header fib4-score">FIB-4</div>
                  </td>
                </tr>
                <tr>
                  <td class="fib4-borderbottom">
                    <div class="fib4-value fib4-age">{{avatarData.age}}</div>
                  </td>
                  <td class="fib4-borderbottom">
                    <div class="fib4-times">x</div>
                  </td>
                  <td class="fib4-borderbottom">&nbsp;</td>
                  <td class="fib4-borderbottom">
                    <div class="fib4-value fib4-ast">{{avatarData.ast}}</div>
                  </td>
                  <td rowspan="4" class="fib4-td-bottom fib4-desktop">
                    <div class="fib4-fib4">{{avatarData.fib4}}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="fib4-header">Platelet Count <em>(10<sup>9</sup>/L)</em></div>
                  </td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    <div class="fib4-value fib4-platelet">{{avatarData.platelet}}</div>
                  </td>
                  <td>
                    <div class="fib4-times">x</div>
                  </td>
                  <td rowspan="2" valign="bottom">
                    <div class="fib4-sqrt">√</div>
                  </td>
                  <td class="fib4-bordertop">
                    <div class="fib4-header">ALT <em>(U/L)</em></div>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>
                    <div class="fib4-value fib4-alt">{{avatarData.alt}}</div>
                  </td>
                </tr>
              </table>
              <table border="0" cellspacing="0" cellpadding="0" width="100%" class="fib4-mobile">
                <tr>
                  <td class="fib4-td-top">
                    <div class="fib4-header fib4-score fib4-mobile">FIB-4</div>
                  </td>
                </tr>
                <tr>
                  <td class="fib4-td-bottom">
                    <div class="fib4-fib4 fib4-mobile">{{avatarData.fib4}}</div>
                  </td>
                </tr>
              </table>
            </div>
          </v-col>
        </v-row>
        <v-row style="width: 100%;">
          <v-col>
            <h5
              class="text-center text-break mx-8 mt-5 mb-0" 
              >
              Click <span style='color:#ff9800;'><b>CONTINUE</b></span> to proceed.
            </h5>
          </v-col>
        </v-row>
    </v-card-text>
  </step-template>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import StepTemplate from "/src/components/steps/StepTemplate.vue";
import { controllers } from "chart.js";

export default {
  name: "StepNotification",
  components: {
    StepTemplate,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    step: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    subStep: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    title: {
      type: String,
      default: "",
      required: false,
    },
  },
  data() {
    return {      
      avatarData: {
        age: null,
				ast: null,
				alt: null,
				platelet: null,
				fib4: null
      }
    };
  },
  computed: {
    ...mapState({
      avatar: (state) => state.avatar,
      caseData: (state) => state.caseData,
      state: (state) => state,
    }),
    avatarVariables() {
      if (JSON.parse(this.avatar.variables)) {
        return JSON.parse(this.avatar.variables);
      }
      return null;
    }
  },
  created() {
    this.init();
  },
  mounted() {
    this.fadeInElements();
  },
  methods: {
    ...mapMutations(["setContinueButtonEnabled"]),
    ...mapActions(["setCaseDataValue"]),
    init() {
      this.setContinueButtonEnabled(true);
      this.setAvatarData();
      this.setAvatarCaseData();
    },
    setAvatarData() {
      if (!this.avatarVariables || !this.avatarVariables.override) {
        return;
      }
      const override = this.avatarVariables.override;
      for (const i in override) {
        if (override[i].subStep && override[i].subStep.id === this.subStep.id) {
          if (override[i].subStep.props)
            this.addAvatarData(override[i].subStep.props);
        }
      }
    },
    addAvatarData(overrideProps) {
      for (const key in overrideProps) {
        this.avatarData[key] = overrideProps[key];
      }
    },
    setAvatarCaseData() {
      const data = this.avatarData;
      for (var key in data) {
        this.setCaseDataValue({
          key: "avatarFib4_" + key,
          value: data[key]
        });
      }
    },
    fadeInElements() {
      const elements = [
        document.querySelector('.fib4-age'),
        document.querySelector('.fib4-ast'),
        document.querySelector('.fib4-platelet'),
        document.querySelector('.fib4-alt'),
        document.querySelector('.fib4-fib4')
      ];

      let delay = 0;
      elements.forEach((element, index) => {
        setTimeout(() => {
          element.style.transition = 'opacity 3s';
          element.style.opacity = 1;
        }, delay);
        delay += 750;
      });
    }
  }
};
</script>
<style>
  .fib4 {
    width: 100%;
  }
  .fib4 table {
    margin: 0 auto;
    max-width: 600px;
    margin-bottom: 60px;
  }
  .fib4-header {
    font-weight: bold;
    font-size: 12px;
    padding-top: 10px;
    line-height: 25px;
  }
  .fib4-header em {
    font-weight: normal;
    color: #888;
  }
  .fib4-score {
    font-size: 25px;
    margin-top: 10px;
  }
  .fib4-value {
    font-size: 30px;
    font-weight: bold;
    opacity: 0;
  }
  .fib4-age,
  .fib4-ast {
    margin-bottom: 10px;
  }
  .fib4-fib4 {
    font-size: 60px;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    opacity: 0;
  }
  .fib4-equals {
    font-size: 60px;
    padding: 10px;
  }
  .fib4-times {
    font-size: 20px;
  }
  .fib4-sqrt {
    font-size: 50px;
    text-align: right;
  }
  .fib4-bordertop {
    border-top: 3px solid #000;
  }
  .fib4-borderbottom {
    border-bottom: 3px solid #000;
  }
  .fib4-td-top,
  .fib4-td-bottom {
    background: rgb(219, 235, 203);
  }
  .fib4-td-top {
    border-top-right-radius: 15px;
  }
  .fib4-td-bottom {
    border-bottom-left-radius: 15px;
  }

  .fib4 table.fib4-mobile {
    display: none;
  }
  @media only screen and (max-width: 600px) {
    .fib4-header {
      font-weight: bold;
      font-size: 10px;
      padding-top: 10px;
      line-height: 10px;
    }
    .fib4-value {
      font-size: 20px;
    }
    .fib4-fib4 {
      padding: 40px 0;
      opacity: 1;
    }
    .fib4-equals {
      font-size: 20px;
    }
    .fib4-times {
      font-size: 20px;
    }
    .fib4-score {
      font-size: 20px;
    }
    .fib4 table.fib4-mobile {
      display: table;
      width: 300px;
      margin-bottom: 0;
    }
    .fib4 .fib4-desktop {
      display: none;
    }
    .fib4 table.fib4-mobile td {
      padding: 0;
    }
  }
</style>
